.speakers {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    /* height: 750px; */
    padding: 100px 0 0px 0;
}

.speakers-container {
    max-width: 1300px;
}

.speakers-intro {
    display: inline-block;
    justify-content: center;
    width: 100%;
    text-align: center;
    color: var(--azul-escuro);
}

.elements {
    padding: 50px;
    display: inline-flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
}

@media (max-width: 500px) {
    .speakers {
        padding: 50px 0 0px 0;
    }
    .speakers-intro {
        font-size: 14px;        
    }

    .elements {
        padding: 0px;
    }
    
}

