.main {
    white-space: pre-line;
    text-align: center;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: black;
    background: linear-gradient(207deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 60%, rgba(241, 41, 51, 0) 100%);
    /* background: rgb(41,59,62);
    background: linear-gradient(207deg, rgba(41,59,62,1) 0%, rgba(2,0,36,1) 50%, rgba(241, 41, 51, 0) 100%); */
    /* background: linear-gradient(207deg, rgba(41,59,62,1) 0%, rgba(2,0,36,1) 50%, rgba(241,41,52,1) 100%); */
    padding: 80px 0 120px 0;
    /* margin-top: 90px; */
    background-attachment: fixed;
    color: white;
}

.main *::selection   {
    color: black;
    background: white;
}

.texto-1 {
    color: white;
    text-align: left;
    display: inline-flex;
    margin: 5px;
    font-size: 25px;
    /* justify-content: center; */
    align-items: center;
}

.texto-1 img {
    width: 325px;
    height: auto;
}


.registerButton {
    background-color: #f12934;
    border-radius: 50px;
    border-color: transparent;
    padding: 15px 30px;
    font-weight: 500;
    font-size: 20px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.6);
}

.registerButton:hover {
    cursor: pointer;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-color: #f12934;
    transition: 0.2s;
    /* font-weight: 400; */
}

.registerButton a {
    text-decoration: none;
}

.registerButton a:visited, .registerButton a:link {
    color: white;
}

.registerButton a:-webkit-any-link {
    color: white;
}


.container_pattern {
    background: radial-gradient(circle at 40% 40%, #f12934 25%, transparent 26%),radial-gradient(circle at 60% 60%, #f1293480 25%, transparent 26%);
    background-size: 3em 3em;
    background-color: #000;
    opacity: 1;
    /* background-attachment: fixed; */
}

.main h2 {
    margin-top: -30px;
    margin-bottom: 40px;
    font-size: 20px;
}

/* Responsivity */

@media (max-width: 800px) {
    .main {
        padding: 80px 0 120px 0;
        background-attachment: local;
    }

    .texto-1 {
        width: 100vw;
        font-size: 3vw;
    }

    .texto-1 img {
        max-width: 325px;
        width: 40vw;
        height: auto;
    }

    .registerButton {
        padding: 10px 15px;
        font-size: 17px;
    }

    .main h2 {
        font-size: 3vw;
    }
    
}

@media (max-width: 750px) {
    /* .main {
        margin-top: 70px;
    } */
}

@media (max-width: 500px) {
    .main {
        height: 700px;
        width: 100vw;
        padding: 0px;
    }

    .texto-1 {
        display: block;
        width: 220px;
        font-size: 13px;
        text-align: center;
    }

    .texto-1 h1 {
        margin-top: 0;
    }

    .texto-1 img {
        width: 200px;
    }

    .main h2 {
        margin-top: 0px;
        font-size: 13px;
        margin-bottom: 15px;
    }
}

@media (max-width: 250px) {
    .texto-1 {
        width: 200px;
        font-size: 11px;
    }
}