.poweredby {
    border: solid 1px white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.poweredby a {
    padding: 0px 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    text-decoration: none;
}

.poweredby a:visited, .poweredby a:link {
    color: white;
}

.poweredby a:-webkit-any-link {
    color: white;
}

.poweredby p {
    margin: 0;
    margin-bottom: 2px;
}