.partners {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 100px 0 50px 0;
    color: var(--azul-escuro);
}

.partnersHeader {
    padding-bottom: 40px;
    text-align: center;
}

.partnersContainer {
    display: flex;
    justify-content:center;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
    column-gap: 100px;
    row-gap: 50px;
    flex-wrap: wrap;
}

.sponsorsContainer {
    display: flex;
    justify-content:center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    min-height: 200px;
    column-gap: 24px;
    row-gap: 30px;
    flex-wrap: wrap;
}

.partners-button {
    background-color: black;
    color: white;
    border: solid 2px transparent;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 18px;
    transition: background-color 200ms, color 200ms;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
}

.partners-button:hover {
    background-color: transparent;
    border: solid 2px black;
    color: black;
    cursor: pointer;
}

.partnerCard img {
    max-width: 200px;
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.partnerCard {
    height: fit-content;
}

.partnerCard2 img {
    max-width: 200px;
    /* border-radius: 10px; */
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
}

.partnerCard2 {
    height: fit-content;
}

.sponsorCard {
    height: fit-content;
}

.sponsorCard img {
    max-width: 550px;
    /* border-radius: 10px; */
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
}

.partner-modal {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(4px);
}

.partner-modal-container {
    background-color: black;
    width: fit-content;
    height: fit-content;
    padding: 50px;
    border-radius: 10px;
    color: white;
}

.partners-button-container {
    margin-top: 40px;
}

.modal-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.modal-form h2 {
    text-align: center;
}

.form-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-evenly;
}

.form-item {
    display: flex;
    flex-direction: column;
}

.form-item input {
    margin-top: 5px;
    width: 500px;
    padding: 5px;
}

.form-item textarea {
}

.mauticform-row.mauticform-required .mauticform-label:after { color: #e32; content: " *"; display: inline; }

.mauticform-button {
    background-color: white;
    padding: 0 10px;
    border-radius: 10px;
    border-color: transparent;
    border: solid 1px white;
    font-weight: 500;
    font-size: 15px;
    align-self: center;
    transition: background-color 200ms;
}

.mauticform-button:hover {
    background-color: black;
    border-color: white;
    color: white;
    cursor: pointer;
}

.mauticform-close-button {
    color: white;
    background-color: transparent;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border-color: transparent;
    border: solid 1px white;
    font-weight: 500;
    font-size: 15px;
    align-self: center;
    transition: background-color 200ms;
}

.mauticform-close-button:hover {
    background-color: white;
    border-color: black;
    color: black;
    cursor: pointer;
}

@media (max-width: 700px) {
    .partners {
        padding: 50px 0 0px 0;
    }
    .partnersHeader {
        font-size: 14px;
    }
    .partnersContainer {
        row-gap: 25px;
        column-gap: 25px;
        justify-content:space-evenly;
    }
}

@media (max-width: 750px) {
    .partner-modal {
        top: 90px;
    }
    .partner-modal-container {
        width: 100%;
        height: 100vh;
        overflow: scroll;
    }
    .modal-form {
        margin-top: 0;
        height: auto;
    }
}

@media (max-width: 550px) {
    .form-item input {
        margin-top: 5px;
        width: 100%;
        padding: 5px;
    }
    
    .sponsorCard img {
        max-width: 300px;
        border-radius: 10px;
        /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    }
}

@media (max-width: 330px) {
    
    .sponsorCard img {
        max-width: 250px;
        border-radius: 0px;
        /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    }

    .partnerCard2 img {
        max-width: 90px;
    }
}