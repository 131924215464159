
.header {
    height: 90px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	background-color: #000;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 10;
    color: white;
}
.menu {
    width: 80vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: white;
    max-width: 1000px;
}

.navigators {
    display: flex;
    justify-content: space-around;
	align-items: center;
    /* background-color: #f12934; */
    width: 100%;
    min-width: 400px;
}

.menu a, .menu img {
    cursor: pointer;
    text-decoration: none;
}

.menu img {
    width: 120px;
}

.menu a:visited, .menu a:link {
    color: white;
}

.menu a:-webkit-any-link {
    color: white;
}

.menu a:hover {
    color: #f12934;
}

.menuButton {
    padding: 5px 10px;
    font-size: 15px;
    /* font-weight: 400; */
}

.hamburger {
    display: none;
}

/* .menuButton:hover {
    cursor: pointer;
    color: white;
    background-color: #000;
    border-color: #f12934;
    transition: 0.2s;
} */

@media (max-width: 1122px) {
    .menu {
        display: flex;
        align-items: center;
    }
}

@media (max-width: 850px) {
    .menu {
        width: 100vw;
        font-size: 14px;
    }
    .menu img {
        margin-left: 10px;
    }
    .navigators {
        /* justify-content: space-evenly; */
    }
    .header {
        width: 100vw;
    }
    .menuButton {
        padding: 2px 5px;
        font-size: 14px;
    }
}

@media (max-width: 750px) {
    /* hamburger menu expanding animation */
    .header {
        height: 70px;
        transition: height 300ms;
        align-items: start;
    }
    .menu {
        height: 70px;
        align-items: center;
    }
    .menu img {
        height: 80px;
        margin-left: 10px;
    }
    .menu a:hover {
        color: white;
    }
    
    .expanding {
        height: 470px;
    }


    /* toggle hambuger menu */
    .hamburger {
        display: flex;
        cursor: pointer;
        width: 50px;
    }
    .navigators {
        display: none;
        flex-direction: column;
        position: absolute;
        width: 100%;
        height: 400px;
        top: 70px;
        left: 0;
        background-color: black;
        font-size: 18px;
        justify-content: start;
        gap: 45px;
        width: 100%;
        min-width: 0;
    }
    .menu button {
        display: none;
    }
    .menu {
        justify-content: space-between;
    }

    .hamburger-show {
        display: flex !important;
    }
    .hamburger-button-show {
        display: flex !important;
    }
}

@media (max-width: 200px) {
    .menu {
        height: 80px;
    }
    .menu img {
        display: none;
    }
}