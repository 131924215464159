.faq {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.faq *:not(h1)::selection   {
    color: black;
    background: white;
}

.faq-container {
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.faqitem-container {
    text-align: left;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 500px;
}

.faqitem-header {
    border-radius: 10px;
    background-color: black;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 6px 0;
    transition: height 300ms;
    cursor: pointer;
}
.faqitem-header h2{
    margin: 0;    
    margin-left: 15px;
    font-weight: 400;
    font-size: 22px;
}

.faqitem-body {
    display: none;
    width: 100%;
    /* min-height: 70px; */
    background-color: black;
    border-radius: 0 0 10px 10px;
    border-top: solid 1px white;
}

.faqitem-body p {
    padding: 0 15px;
    text-align: justify;
}

.fix-border {
    border-radius: 10px 10px 0 0;
}

.show-answer {
    display: flex;
}

@media (max-width: 540px) {
    .faq-container {
        display: flex;
        align-items: center;
    }
    .faqitem-container {
        width: 80vw;
    }
    .faqitem-header h2{
        font-size: 18px;
    }
    .faq-header {
        font-size: 26px;
    }
}