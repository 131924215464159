.scheduleTableContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
	background-color: transparent;
	padding-bottom: 50px;
}

/* .scheduleTableContainer table {
	height: 520px;
} */

.labelsRow td {
    padding: 10px;
    color: white;
}
.labelsRow td:first-child {
	border-radius: 0 0 0 10px;
}
.labelsRow td:last-child {
	border-radius: 0 0 10px 0;
}

.field {
    text-align: center;
}

.speaker-name-container {
	display: flex;
	justify-content: center;
}

.speaker-name-container:not(:last-child) {
	margin-bottom: 5px;
}

.speaker-name tr {
	font-size: 14px;
}

tbody tr:first-child td:first-child {
	border-radius: 10px 0 0 0;
}
tbody tr:first-child td:last-child {
	border-radius: 0 10px 0 0;
}

.headContainer td {
    background-color: var(--vermelho);
}

.scheduleTableContainer table {
    border-radius: 10px;
}

table {
	width: 600px;
	border-collapse:separate; 
	border-spacing: 0;
    color: var(--azul-escuro);
	background-color: white;
	box-shadow: 2px 0px 20px rgba(0,0,0,0.1);
	overflow: hidden;
}

td {
    border: solid 4px #e5e5e5;
    border-style: none none none none;
    padding: 15px;
    font-size: 16px;
    max-width: 250px;
}

tbody td {border-style: solid solid none none;}
tbody td:last-child {border-style: solid none none none;}
tbody td:first-child {background-color: var(--vermelho); color: white}

th,
td {
	background-color: rgba(255,255,255,0.2);
}

th {
	text-align: left;
}

tbody {
	tr {
		&:hover {
			background-color: rgba(241, 41, 52, 0.1);
		}
	}
	td {
		position: relative;
		&:hover {
			&:before {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				top: -9999px;
				bottom: -9999px;
				background-color: rgba(255,255,255,0.2);
				z-index: -1;
			}
		}
	}
}