.event {
    display: flex;
    background: rgba(0, 0, 0, 1);
    color: white;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    height: auto;
}
.event {
    gap: 20px;
}
.event-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
}

.event-image img {
    width: 30vw;
    max-width: 450px;
}

.event-container:first-child {
    align-items: flex-end;
}

.event-container:last-child {
    align-items: flex-start;
}

.event-container p {
    max-width: 450px;
    font-size: 20px;
    width: 500px;
    line-height: 30px;
    text-align: justify;
}

.event-container h1 {
    max-width: 450px;
    width: 500px;
    text-align: left;
    margin-bottom: 0;
}

.event-container *::selection {
    color: black;
    background: white;
}

@media (max-width: 1080px) {
    .event {
        width: 100vw;
    }
    .event-container {
        max-width: 450px;
        width: 40vw;
        font-size: 17px;
    }
    .event-container h1 {
        /* font-size: 26px; */
        width: inherit;
        /* text-align: center; */
    }

    .event-container p {
        width: inherit;
        font-size: 17px;
        /* text-align: left; */
        line-height: 30px;
        text-align: justify;
    }
    .event-container img {
        max-width: 450px;
        width: 40vw;
    }
    .pre-eventcard {
        width: auto;
    }
    .eventcard {
        height: 50px;
        padding: 0 15px 0 15px;
        font-size: 14px;
    }
}

@media (max-width: 850px) {
    .event-container h1 {
        /* font-size: 24px; */
        width: inherit;
        text-align: center;
    }

    .event-container p {
        width: inherit;
        font-size: 17px;
        /* text-align: left; */
        line-height: 30px;
        text-align: justify;
    }
}

@media (max-width: 730px) {
    .event {
        flex-direction: column;
        /* background: linear-gradient(207deg, rgba(2,0,36,0.8) 0%, rgba(0,0,0, 1) 50%, rgba(241, 41, 51, 1) 100%); */
    }
    .event-container {
        max-width: 300px;
        width: 80vw;
        display: flex;
        flex-direction: column;
        /* justify-content: flex-end; */
        margin-bottom: 0px;
        font-size: 17px;
    }
    .event-container:first-child {
        align-items: center;
    }
    
    .event-container:last-child {
        align-items: center;
        margin-top: 0;
    }

    .event-container h1 {
         text-align: center;
    }

    .event-container p {
        font-size: 17px;
        /* text-align: left; */
        line-height: 30px;
        text-align: justify;
        /* margin-left: 25px; */
    }
    .pre-eventcard {
        width: auto;
    }

    .eventcard {
        height: 50px;
        /* width: 500px; */
        padding: 0 10px 0 10px;
        border-radius: 50px;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
        color: var(--azul-escuro);
        justify-content: center;
        align-items: center;
        font-size: 12px;
    }

    .event-image img {
        width: 60vw;
    }
    
}

@media (max-width: 730px) {
    .event-container {
        font-size: 13px;
    }
}