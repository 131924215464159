.qrcode-container {
    height: 230px;
    width: fit-content;
    overflow: hidden;
    background-color: white;
    border: solid 2px black;
    border-radius: 10px;
    margin-top: 10px;

}

.qrcode-container img {
    object-fit: cover;
    width: 200px;
}