.footer {
    /* height: 300px; */
    display: flex;
    background-color: black;
    margin-top: 100px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 100px 0;
    color: white;
}

.footer-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.footer-items {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.right-item {
    font-size: 30px;
    gap: 25px;
}

.footer-items a {
    cursor: pointer;
}

.footer-items img {
    width: 140px;
}

.second-container {
    width: 65%;
}

.footer-separator {
    width: 65%;
    border-top: solid 2px white;
}

.footer-items a:visited, .footer-items a:link {
    color: white;
}

.footer-items a:-webkit-any-link {
    color: white;
}

.footer-container:last-child {
    width: 100%;
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (max-width: 400px) {
    .footer-container {
        flex-direction: column;
        gap: 20px;
    }
    .footer-container:last-child {
        gap: 20px;
        align-items: center;
        justify-content: center;
        margin-right: 0;
    }
}