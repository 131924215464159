@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;700&display=swap");

*{
	font-family: "Barlow";
}

html{
	scroll-behavior:smooth;
}
:root {
	/* ### variáveis e cores ###*/
	--vermelho: #f12934;
	--cinza-claro: rgba(41,59,62,1);
	--azul-escuro: black;
	/* --azul-escuro: rgb(3, 0, 66); */

}

*::selection   {
    color: white;
    background: black;
}

.app{
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	/* background-image: linear-gradient(90deg, rgba(223,223,223,1) 20%, rgba(255,255,255,1) 50%, rgba(223,223,223,1) 80%) !important; */
}