.schedule {
    width: 100%;
    display: flex;
    justify-content: center;
    color: var(--azul-escuro);
    padding: 70px 0 0px 0;
}

.showBoard {
    display: none;
}

.full-board {
    display: flex !important;
}

.reduced-board {
    display: none !important;
}

.scheduleItems {
    display: inline-block;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.start {
    color: var(--vermelho);
    font-weight: bold;
}

.board {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.board-title {
    width: 600px;
    background-color: var(--vermelho);
    border-radius: 10px;
    height: 30px;
    margin-bottom: -10px;
}

.board-title h2 {
    margin-top: 2px;
    font-size: 20px;
    color: white;
}

.board-title {
    margin-bottom: 30px;
}

.board-items {
    width: 600px;
    height: 500px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 20px;
}

.boarditems-container {
    height: 120px;
    width: 150px;
    display: flex;
    flex-direction: column;
    /* background-color: red; */
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);;
    padding: 0 10px 0 10px;
    border-radius: 10px;
    
    transition: background-color 300ms linear, width 200ms linear, transform 0.3s;
    cursor: pointer;
}

.boarditems-container h2 {
    font-size: 18px;
    margin-bottom: -10px;
    font-weight: 300;
}

.boarditems-container h3 {
    font-size: 16px;
    margin-bottom: -10px;
    font-weight: 500;
}

.boarditems-intenarl-container {
    height: 120px;
    width: 150px;
    display: flex;
    flex-direction: column;
}


@keyframes flip_animation {
    0%{
        color: transparent;
        transform: perspective(500px) rotateY(0deg);
      }
      100%{
        color: transparent;
        transform: perspective(500px) rotateY(-180deg);
      }
}

.boarditems-intenarl-container span {
    font-size: 14px;
    padding-top: 12px;
    color: var(--vermelho);
}

.pre-flip {
    background-color: black;
    color: white;
    font-size: 14px;
}

.pre-flip:hover {
    animation-name:flip_animation;
    animation-duration:250ms;
}

.pre-flip .boarditems-intenarl-container {
    display: flex;
    justify-content: center;
}

.pre-flip span {
    color: transparent;
    font-size: 0;
}

@media (max-width: 630px) {
    .board-title {
        width: 80vw;
    }
    .full-board {
        display: none !important;
    }

    .showBoard {
        display: contents;
    }

    .showTable {
        display: none;
    }

    .reduced-board {
        display: flex !important;
    }
    .slider-board-items-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        height: 200px;
        width: 70vw;
        overflow: hidden;
    }
    .boarditems:first-child{
        margin-left: 6px;
    }

    .slider-board-items {
        align-items: center;
        backdrop-filter: blur(4px);
    }
    .slider-board-items span {
        margin: 5px;
        cursor: pointer;
        color: var(--vermelho);
    }
}