.stayin {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stayin-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

@media (max-width: 250px) {
    .stayin h1 {
        font-size: 25px !important;
    }
}