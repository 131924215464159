
.hackheader {
    height: 100px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	background-color: #f12934;
    display: flex;
    justify-content: center;
    /* position: fixed; */
    color: white;
    margin-top: 90px;
}

.hackmenu {
    width: 80vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    color: white;
    max-width: 1000px;
}

.hackmenu h3{
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 5px;
}

.hacknavigators {
    display: flex;
    justify-content: space-around;
	align-items: center;
    /* background-color: #f12934; */
    width: 100%;
    min-width: 400px;
    flex-direction: column;
    gap: 10px;
}

.hackregisterButton {
    background-color: #000;
    border-radius: 50px;
    border-color: transparent;
    padding: 15px 30px;
    font-weight: 500;
    font-size: 20px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.6);
}

.hackregisterButton:hover {
    cursor: pointer;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-color: #f12934;
    transition: 0.2s;
    /* font-weight: 400; */
}

.hackregisterButton a {
    text-decoration: none;
}

.hackregisterButton a:visited, .hackregisterButton a:link {
    color: white;
}

.hackregisterButton a:-webkit-any-link {
    color: white;
}

.hackregisterButton .hackmenuButton:hover a {
    color: white !important
}


.hackmenu a, .hackmenu img {
    cursor: pointer;
    text-decoration: none;
}

.hackmenu img {
    width: 120px;
}

.hackmenu a:visited, .hackmenu a:link {
    color: white;
}

.hackmenu a:-webkit-any-link {
    color: white;
}

/* .hackmenu a:hover {
    color: #f12934;
} */

.hackmenuButton {
    padding: 5px 10px;
    font-size: 15px;
    /* font-weight: 400; */
}

.hamburger {
    display: none;
}

/* .hackmenuButton:hover {
    cursor: pointer;
    color: white;
    background-color: #000;
    border-color: #f12934;
    transition: 0.2s;
} */

@media (max-width: 1122px) {
    .hackmenu {
        display: flex;
        align-items: center;
    }
}

@media (max-width: 850px) {
    .hackmenu {
        width: 100vw;
        font-size: 14px;
    }
    .hackmenu img {
        margin-left: 10px;
    }
    .navigators {
        /* justify-content: space-evenly; */
    }
    .header {
        width: 100vw;
    }
    .hackmenuButton {
        padding: 2px 5px;
        font-size: 14px;
    }
}

@media (max-width: 800px) {
    .main {
        padding: 80px 0 120px 0;
        background-attachment: local;
    }
}

@media (max-width: 750px) {
    /* hamburger hackmenu expanding animation */
    .hackheader {
        margin-top: 70px;
        height: 100px;
        transition: height 300ms;
        align-items: start;
    }
    .hackmenu {
        height: 70px;
        align-items: center;
    }
    .hackmenu img {
        height: 80px;
        margin-left: 10px;
    }
    .hackmenu a:hover {
        color: white;
    }
    
    .expanding {
        height: 470px;
    }


    /* toggle hambuger menu */
    .hamburger {
        display: flex;
        cursor: pointer;
        width: 50px;
    }
    .navigators {
        display: none;
        flex-direction: column;
        position: absolute;
        width: 100%;
        height: 400px;
        top: 70px;
        left: 0;
        background-color: black;
        font-size: 18px;
        justify-content: start;
        gap: 45px;
        width: 100%;
        min-width: 0;
    }
    .hackmenu {
        justify-content: space-between;
    }

    .hackmenu h3 {
        margin-top: 20px;
    }

    .hamburger-show {
        display: flex !important;
    }
    .hamburger-button-show {
        display: flex !important;
    }
}

@media (max-width: 320px) {
    .hackmenu h3{
        font-size: 14px;
    }
}

@media (max-width: 200px) {
    .hackmenu {
        height: 80px;
    }
    .menu img {
        display: none;
    }
}