.about {
    white-space: pre-line;
    text-align: center;
    padding: 50px 0;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 100px 0 100px 0; */
}

.about-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 50px;
    width: 100%;
}

.about-image img {
    max-width: 500px;
}
.about-image {
    width: fit-content;
    max-width: 450px;
}

.about-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
}

.about-items:first-child {
    justify-content: right;
}

.about-items:last-child {
    justify-content: left;
}

.about-text {
    color: var(--azul-escuro);
    text-align: left;
    display: flex;
    flex-direction: column;
    /* margin: 5px; */
    align-items: left;
    width: 500px;
}

.about-text p {
    color: var(--azul-escuro);
    font-size: 20px;
    /* text-align: left; */
    width: 500px;
    margin-top: -10px;
    line-height: 30px;
    text-align: justify;
}

.about-text::selection {
    color: white;
    background-color: black;
}

.dino {
    object-fit: contain;
}

.dino img {
    width: 200px;
    border-radius: 50%;
}

.pre-dino {
    width: 200px;
}

@media (max-width: 1080px) {
    .about {
        width: 100vw;
    }
    .about-container {
        width: 100vw;
    }
    .about-items {
        width: 50%;
    }
    .about-image img {
        max-width: 450px;
        width: 40vw;
    }
    .about-image {
        max-width: 450px;
        width: 40vw;
    }
    .about-text {
        font-size: 18px;
        max-width: 450px;
        width: 40vw;
    }
    .about-text p {
        font-size: 17px;
        max-width: 450px;
        width: 40vw;
    }
}

@media (max-width: 730px) {
    .about-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .about-items {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .about-text {
        text-align: center;
        font-size: 17px;
        max-width: 400px;
        width: 80vw;
    }
    .about-text p {
        font-size: 15px;
        max-width: 400px;
        width: 80vw;
    }
}

@media (max-width: 500px) {
    .about-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .about-items {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .about-image img {
        max-width: 300px;
        width: 80vw;
    }
    .about-image {
        max-width: 300px;
        width: 80vw;
    }
    .about-text {
        text-align: center;
        font-size: 17px;
        max-width: 300px;
        width: 80vw;
    }
    .about-text p {
        font-size: 15px;
        max-width: 300px;
        width: 80vw;
    }
}