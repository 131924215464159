.forwho {
    padding: 50px 0;
    background-color: white;
    color: black;
    border-bottom: solid 1px black;
}

.forwho-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.forwho-container h1 {
    margin-bottom: 0;
}

.forwho-ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.forwho-ul ul {
    width: 400px;
    line-height: 40px;
    border: solid 1px black;
    border-radius: 10px;
    padding: 20px 10px 20px 30px;
    /* list-style: none; */
    margin-left: 0;
    color: white;
    background-color: black;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
}

.forwho-ul li {
    font-size: 20px;
    /* text-indent: -1em; */

}

.forwho-ul li:before {
    /* content: url('../../imgs/nordx_icon_20x.png'); */
    color: var(--vermelho);
    padding-right: 5px;
    transition: content 1s;
}

.forwho-ul li:hover:before {
    color: white;
}