.card {
    width: 266;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 0 0 15px 0;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    /* box-shadow: 0 3px 10px rgba(255, 255, 255, 0.2); */
    margin-bottom: 10px;
    transition: transform 0.3s, box-shadow 0.3s;;
}

.card-name {
    margin-top: 3px;
    font-size: 17px;
    color: var(--azul-escuro);
}

.card-title {
    font-size: 13px;
    font-style: italic;
    color: var(--cinza-claro);
    max-width: 130px;
    text-align: center;
}

.card-icon {
    width: fit-content;
    padding: 20px 20px 10px 20px;
    
}

.card-image{
    width: 140px;
    height: 140px;
    border-radius: 50%;
    object-fit:cover;
}

.card-company{
    object-fit:cover;
    margin-top: 117px;
    margin-left: 90px;
    position:absolute;
}

.card-company img{
    background-color: white;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: 2px solid var(--azul-escuro);
    object-fit: contain;
}

.card:hover {
    transform: perspective(500px) rotateY(-5deg) rotateX(5deg);
    box-shadow: 5px 5px 10px 3px rgb(0 0 0 / 0.2);
}

@media (max-width: 900px) {
    .card:hover {
        transform: perspective(0) rotateY(0deg) rotateX(0deg);
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }
}

@media (max-width: 500px) {
    .card {
        margin-bottom: 0;
    }

    .card-image {
        width: 100px;
        height: 100px;
    }
    .card-company{
        margin-top: 80px;
        margin-left: 75px;
    }
    .card-company img{
        width: 40px;
        height: 40px;
    }

    .card-name {
        margin-top: 0px;
        font-size: 14px;
    }
    .card-title {
        font-size: 11px;
    }
    .card-icon {
        padding: 10px 15px 10px 15px;
        
    }
}